import {Company, Loan} from "@/lib/CompanyInterface";

export class LocalService {
    private static updateLocalCompanies(company: Company[]) {
        localStorage.setItem('YourLocCompanies', JSON.stringify(company))
    }

    public static removeLoan(index: number)
    {
        const loans = this.getLoans();
        loans.splice(index, 1);

        this.updateLoans(loans);
    }

    public static updateLoan(index: number, loan: Loan): void
    {
        const loans = this.getLoans();
        loans[index] = loan;

        this.updateLoans(loans);
    }

    private static updateLoans(loans: Loan[]) {
        localStorage.setItem('YouLoans', JSON.stringify(loans))
    }

    public static getLoanByIndex(index: number): Loan
    {
        const loans = this.getLoans();

        return loans[index];
    }
    static updateLocalCompany(company: Company) {
        const arr: Company[] = this.getLocalCompanies()
        arr.forEach((el: Company, index: number) => {
            if (company.id === el.id) {
                arr[index] = company
            }
        })
        this.updateLocalCompanies(arr)
    }
    static getLocalCompanies() {
        return JSON.parse(localStorage.getItem('YourLocCompanies') || '[]');
    }

    static getLoans() {
        return JSON.parse(localStorage.getItem('YouLoans') || '[]');
    }

    static getLocalCompany(id: number) {
        return this.getLocalCompanies().find((el: Company) => el.id === id)
    }
    static getLocalCompanyByName(name: string): Company
    {
        return this.getLocalCompanies().find((el: Company) => el.name === name)
    }
    static submitNewLocal(name: string): number
    {
        if (name) {
            const newLocComp: Company = { id: 0, name: '', balance: 0, bills: [] };
            newLocComp.name = name;

            const yourLocComps = this.getLocalCompanies()
            yourLocComps.push(newLocComp);
            yourLocComps.forEach((o: Company, i: number) => o.id = i + 1);
            this.updateLocalCompanies(yourLocComps);
            return newLocComp.id;
        }

        throw new Error('name can not be empty');
    }

    static submitNewLoan(name: string, date: string, balance: number): Loan
    {
        if (name) {
            const newLocComp: Loan = {
                name: '',
                date: '',
                balance: 0,
                conditions: '',
            };

            newLocComp.name = name;
            newLocComp.balance = balance;
            newLocComp.date = date;

            const yourLocComps = this.getLoans();
            yourLocComps.push(newLocComp);
            this.updateLoans(yourLocComps);

            return newLocComp;
        }

        throw new Error('name can not be empty');
    }

    static removeCompany(id: number) {
        let companies = this.getLocalCompanies()
        companies.forEach((el: Company) => {
            if (el.id === id) {
                companies = Object.values(companies as Company[]).filter((item: Company) => item.id !== id);
            }
        })
        this.updateLocalCompanies(companies)
    }
    static getTotalBalance(): number {
        let total = 0
        LocalService.getLocalCompanies().forEach((el: Company) => {
            total += el.balance;
        })
        return total;
    }

}
