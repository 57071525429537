
import { Options, Vue } from "vue-class-component";
import router from "@/router";
import SlideModal from "@/components/modal/slide-modal.vue";
import { FormatHelper } from "@/lib/formatHelper"
import {notify} from "@kyvg/vue3-notification";
import {LocalService} from "@/services/LocalService";
import {Loan} from "@/lib/CompanyInterface";
import {key} from "@/vuex/store";

@Options({
  computed: {
    key() {
      return key
    }
  },
  components: {
    SlideModal
  }
})

export default class Loans extends Vue {
  total: number = 0;
  createNew: boolean = false;
  newName: string = '';
  newDate: string = '';
  newBalance: number = 0;
  loans: Array<Loan> = [];
  protected formatHelper: FormatHelper = new FormatHelper()

  mounted() {
    this.loans = LocalService.getLoans();

    this.updateTotal();
  }

  beforeRouteEnter() {
    this.loans = LocalService.getLoans();
    this.updateTotal();
  }

  updateTotal(): number {
    let total: number = 0;

    this.loans.forEach((loan) => {
      total = total + loan.balance;
    });


    console.log(total);
    return total;
  }

  toggleCreateMode() {
    this.createNew = !this.createNew;
  }
  submitNewLocal(name: string, date: string, balance: number) {
    const companyNameMinLength = 2
    if (name.length < companyNameMinLength) {
      notify({
        group: 'auth',
        text: 'Min length: ' + companyNameMinLength + ' symbol',
        type: "error"
      })

      return false
    }

    LocalService.submitNewLoan(name, date, balance)
    this.createNew = false;
    this.newBalance = 0;
    this.newName = '';
    this.newDate = '';
    this.loans = LocalService.getLoans()
  }

  public openLean(id: any): void {
    console.log(id);
    router.push(`/lean-details/${id}`)
  }
}
