

import { Options, Vue } from "vue-class-component";
import SlideModal from "@/components/modal/slide-modal.vue";
import router from "@/router";
import { LocalService } from "@/services/LocalService";
import {Loan} from "@/lib/CompanyInterface";
import { FormatHelper } from "@/lib/formatHelper"

@Options({
  components: {
    SlideModal,
  },
  validations: {
  }
})

export default class LoanPage extends Vue {
  public loanId: number = -1;
  public yourCompany: Loan = {
    name: '',
    date: '',
    balance: 0,
    conditions: ''
  };
  protected totalBalance: number = 0;
  protected removeCompanyModal: boolean = false;
  public companyToDelete: number | undefined;
  protected editableMode: boolean = false;
  protected formatHelper: FormatHelper = new FormatHelper()

  public mounted() {
    console.log('OPEN PAGE');
    this.loanId = Number(this.$route.params.id);


    this.yourCompany = LocalService.getLoanByIndex(this.loanId);
    this.totalBalance = this.yourCompany.balance;
  }

  public editLoan() {
    LocalService.updateLoan(this.loanId, this.yourCompany);
    router.push({ path: `/` })
  }

  public removeCompanyConfirm() {
    LocalService.removeLoan(this.loanId)
    this.removeCompanyModal = false
    router.push({ path: `/` })
  }

}
